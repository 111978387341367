import React from "react";

export const ReturnIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7073 4.29289C13.0978 4.68342 13.0978 5.31658 12.7073 5.70711L7.41436 11H19.0002C19.5524 11 20.0002 11.4477 20.0002 12C20.0002 12.5523 19.5524 13 19.0002 13H7.41436L12.7073 18.2929C13.0978 18.6834 13.0978 19.3166 12.7073 19.7071C12.3167 20.0976 11.6836 20.0976 11.293 19.7071L3.58594 12L11.293 4.29289C11.6836 3.90237 12.3167 3.90237 12.7073 4.29289Z"
        fill="#0032FF"
      />
    </svg>
  );
};
